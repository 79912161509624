module.exports = {
  // App Info
  appstore_link: "https://apps.apple.com/us/app/li-connect/id1568364592", // Enter App Store URL.
  playstore_link:
    "https://play.google.com/store/apps/details?id=com.legal_innovators.li_connect", // Enter Google Play Store URL.
  google_analytics_ID: "", // Enter Google Analytics ID or ""
  presskit_download_link: "", // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: "screenshot", // "screenshot" or "video"
  app_url: "https://app.legal-innovators.com", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "LI Connect",
  app_dev_name: "LEGAL INNOVATORS",
  app_description: "LI Connect - your way to stay connected!",
  app_keywords: ["legal", "legal innovators", "business", "corporate"],

  // Company Info
  company_name: "Legal Innovators",
  company_website: "https://www.legal-innovators.com",
  email_address: "dev@legal-innovators.com",
  linkedin_username: null,
  facebook_username: null,
  instagram_username: null,
  twitter_username: null,
  github_username: null,
  youtube_username: null,

  // Features List
  features: [
    {
      title: "All In One",
      description: "Find all the information you need in one place.",
      fontawesome_icon_name: "mobile",
    },
    {
      title: "Stay Up-to-Date",
      description:
        "Company updates, important internal communications as well as training information, surveys, assignments, feedback and personal development metrics for Legal Innovators employees.",
      fontawesome_icon_name: "lightbulb",
    },
    {
      title: "Want To Join Us?",
      description:
        "Download the app to learn more about us, find open positions, and apply!",
      fontawesome_icon_name: "handshake",
    },
  ],
  header_background: "rgba(0, 0, 0, 0.15)",
  topbar_title_color: "#ffffff",
  cover_overlay_color_rgba: "rgba(245, 128, 32, 0.85)",
  device_color: "black", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "ffffff",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `"Helvetica Neue", sans-serif`,
  link_color: "#1d63ea",
  app_title_color: "#ffffff",
  app_dev_name_color: "#ffffff",
  app_description_color: "#ffffff",
  feature_title_color: "#000000",
  feature_text_color: "#666666",
  feature_icons_foreground_color: "#f8971d",
  feature_icons_background_color: "#4d6075",
  social_icons_foreground_color: "#4d6075",
  social_icons_background_color: "#c7c8ca",
  footer_text_color: "#666666",
}
